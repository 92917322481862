import { Component, Input, OnChanges } from '@angular/core';
import { Basket } from '@app/models/basket';
import { ChargeTypes } from '@app/models/charge-types';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { BasketService } from '@app/api/basket.service';
import { IssueTypes } from '@app/models/issue-types';
import { Charge } from '@app/models/Charge';
import { MenuService } from '@app/api/menu.service';

@Component({
  selector: 'app-basket-charges',
  styleUrls: ['./basket-charges.component.scss', './../../shared-basket-styles.scss'],
  templateUrl: './basket-charges.component.html'
})
export class BasketChargesComponent extends AndroWebCoreComponent implements OnChanges {
  @Input() public basket: Basket;
  @Input() public orderComplete: boolean;
  @Input() public showErrorsOnly: boolean;
  @Input() public basketTotal: number = 0;
  @Input() public loyaltyDiscount: number;

  public basketIssues: string[] = [];

  public basketTax: number;
  public basketSubTotal: number;
  public basketDeliveryCharge: number;
  public subTotalIncludingTax: number;
  public minimumSpendSurcharge: number;
  public voucherNarrative: string = 'Voucher';
  public voucherPrice: number;

  constructor(
    private menuService: MenuService,
    private basketService: BasketService
  ) {
    super();
  }

  ngOnChanges(): void {
    this.setBasketCharges();
  }

  /**
  * removes a voucher from the basket
  * @param keepError - if the voucher error should be persisted or not (optional)
  */
  public async removeVoucher(): Promise<void> {
    const response: Basket | null = await this.basketService.deleteVoucherFromBasket(this.basket.Id);

    if (response) {
      response.WantedTimeUtc = this.basket.WantedTimeUtc;
      this.basket = response;
    }
  }

  /**
  * removes loyalty from the current basket
  */
  public removeLoyalty(): void {
    this.subscriptions$[`BasketComponent-redeemLoyalty-basketService-redeemLoyaltyOnBasket()`]
      = this.basketService.redeemLoyaltyOnBasket(this.basket.Id, 0).subscribe();
  }

  /**
  * returns an integer to two decimal places
  * @memberof BasketChargesComponent
  * @public
  */
  public parseFormatNumber(integer: number): string {
    return integer.toFixed(2) || '0.00';
  }

  /**
   * sets the basket charges and checks for issues.
   */
  private setBasketCharges(): void {
    if (!this.basket) {
      return;
    }

    this.basketTax = this.getBasketCharge(this.basket, ChargeTypes.Tax);
    this.basketSubTotal = this.getBasketCharge(this.basket, ChargeTypes.SubTotal);
    this.basketDeliveryCharge = this.getBasketCharge(this.basket, ChargeTypes.Delivery);
    this.minimumSpendSurcharge = this.getBasketCharge(this.basket, ChargeTypes.MinimumSpendSurcharge);
    this.subTotalIncludingTax = this.getBasketCharge(this.basket, ChargeTypes.SubTotalIncludingTax);

    const discountCharge: Charge = this.basket.Charges.find((x: Charge) => x.ChargeType === ChargeTypes.Discount);

    if (discountCharge) {
      this.voucherNarrative = discountCharge.Narrative;
      this.voucherPrice = discountCharge.Price.Amount;
    } else {
      this.voucherNarrative = 'Voucher';
      this.voucherPrice = 0;
    }

    this.checkBasketIssues();
  }

  /**
  * returns the amount of a given charge on a given basket
  * @param basket - the basket
  * @param chargeType - the charge type that needs to be returned
  */
  private getBasketCharge(basket: Basket, chargeType: ChargeTypes): number {
    const amount = basket.Charges?.find((charge) => charge.ChargeType === chargeType)?.Price.Amount;
    return amount ? amount : 0;
  }

  /**
  * checks if there are any basket issues and if so, gets their error narratives to display to the user
  */
  private async checkBasketIssues(): Promise<void> {
    if (this.basket) {
      const displayedIssues: string[] = [];

      if (this.basket.Issues?.length > 0) {
        for (const issue of JSON.parse(JSON.stringify(this.basket.Issues))) {
          if (
            issue.IssueType === IssueTypes.LoyaltyPointsWouldViolateMinimumRemainingOrderValue
            || issue.IssueType === IssueTypes.LoyaltyPointsWouldDiscountMoreThanAllowedValue
            || issue.IssueType === IssueTypes.LoyaltyPointsWouldDiscountMoreThanAllowedPercentage) {
            this.removeLoyalty();
          }

          // don't display postcode issues since address section on checkout will handle it.
          // don't display wanted time issues since time section on checkout will handle it.
          if (
            issue.IssueType === IssueTypes.DeliveryOccasionRequiresDeliveryLocation
            || issue.IssueType === IssueTypes.SiteDoesNotDeliverToDeliveryLocation
            || issue.IssueType === IssueTypes.OccasionIsNotAvailableAtWantedTime
            || issue.IssueType === IssueTypes.SiteIsNotAvailableForAsapOrdersNow
            || issue.IssueType === IssueTypes.SiteDoesNotAcceptAsapOrdersForOccasion
          ) {
            break;
          }

          let message: string;
          if (issue.IssueType === IssueTypes.DealMinimumSpendIsLowerThanBasketSpend) {
            message = issue.Narrative;

            const deal = this.menuService.menuValue?.Deals.find((x) => x.Id === issue.ProductId);

            if (deal) {
              message = `${deal.MinimumSpend ? `${deal.MinimumSpend} minimum` : 'Minimum '} spend has not been met for deal ${deal.Name}. (excludes delivery charges)`;
            } else {
              const e: string = await this.getErrorMessage(issue.IssueType);
              message = (e === 'something has gone wrong' && issue.Narrative) ? issue.Narrative : e;
            }
          } else if (issue.IssueType === IssueTypes.TotalIsLessThanMinimumSpend) {
            const suffix: string = this.basket.DeliveryMinimumSpend ? `than the £${this.parseFormatNumber(this.basket.DeliveryMinimumSpend)}` : `than the`;
            message = `The basket subtotal is less ${suffix} minimum required.`;
          } else {
            const e: string = await this.getErrorMessage(issue.IssueType);
            message = (e === 'something has gone wrong' && issue.Narrative) ? issue.Narrative : e;
          }

          displayedIssues.push(message);
        }
      }

      this.basketIssues = Array.from(new Set(displayedIssues));
    }
  }
}
