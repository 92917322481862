// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --footer-background-colour: #333333;
  --footer-link-colour: #fff;
  --nav-bar-background-colour: #fff;
  --product-content-padding: 0 10px;
}

.basket-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.basket-header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.basket-header-row {
  font-family: Tenant-Bold, Courier, Helvetica, sans-serif;
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 33px;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.basket-close-icon {
  color: #929DB1;
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
}

.basket-close-icon:hover {
  color: #000;
}

.basket-header-toggle {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
  flex: 1;
  opacity: 0.7;
  font-size: 14px;
}

.basket-header-toggle.mat-button-toggle-label-content {
  font-size: 14px;
}

.basket-header-toggle.mat-button-toggle-checked {
  background-color: unset;
  color: #000;
  opacity: 1;
}

.basket-header-toggle.mat-button-toggle-checked .basket-header-toggle-row {
  color: #E3000E;
}

.basket-header-toggle-estimate {
  font-size: 12px;
  line-height: initial;
  text-transform: lowercase;
}

.basket-header-toggle .mat-button-toggle-label-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 40px;
}

.basket-header-toggle-row.text-color-branded {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: initial;
}

.basket-header-toggle-row.text-color-branded mat-icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
  margin-right: 2px;
  margin-top: -4px;
}

.basket-header-toggle-row.text-color-branded span {
  font-weight: bold;
  font-size: 20px;
  line-height: initial;
}

.basket-header-toggle-group.mat-button-toggle-group {
  border-width: 1px 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.07);
  box-shadow: none;
  width: 100%;
}

.basket-header-toggle-disabled,
.basket-header-toggle-disabled.mat-button-toggle-checked {
  opacity: 0.35;
  pointer-events: none;
}

.basket-body {
  overflow-y: auto;
  flex: 1;
}

.no-products {
  text-align: center;
  font-size: 19px;
  padding-top: 10px;
}

.basket-item {
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  padding: 5px;
}

.basket-item:nth-child(odd) {
  background-color: rgba(232, 232, 232, 0.45);
}

.basket-item-hasIssues {
  background-color: #ffdddd;
}

.button-action-1.checkout-button {
  width: 100%;
  margin: 5px 0 0;
  border-radius: 0;
  text-transform: uppercase;
  height: 45px;
  padding: 0;
}

.basket-loyalty {
  display: block;
  margin: 5px;
  padding-top: 5px;
  border-top: 1px solid #E3000E;
}

.basket-voucher {
  display: block;
  margin: 0 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
