import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AndroWebCoreComponent } from '@app/core/AndroWebCoreComponent';
import { Basket } from '@app/models/basket';
import { BasketService } from '@app/api/basket.service';
import { LoyaltySchemeRedeemingRules } from '@app/models/loyalty-scheme-redeeming-rules';
import { Observable, takeUntil } from 'rxjs';
import { IssueTypes } from '@app/models/issue-types';
import { Issue } from '@app/models/Issue';
import { CurrencyPipe } from '@angular/common';
import { LoyaltySchemeEarningRules } from '@app/models/loyalty-scheme-earning-rules';
import { Site } from '@app/models/site';

@Component({
  providers: [CurrencyPipe],
  selector: 'app-basket-loyalty',
  styleUrls: ['./basket-loyalty.component.scss', './../../shared-basket-styles.scss'],
  templateUrl: './basket-loyalty.component.html'
})
export class BasketLoyaltyComponent extends AndroWebCoreComponent implements OnChanges {
  @ViewChild('confirmWantedTime', { static: true }) private _confirmWantedTimeModal: TemplateRef<any>;

  @Input() public orderComplete: boolean;
  @Input() public basket: Basket;
  @Input() public currentSite: Site;
  @Input() public usersLoyaltyPoints: number = 0;
  @Input() public earnablePointsForCurrentBasket: number;

  @Input('userId') private _userId: string;
  @Input('basketTotal') private _basketTotal: number = 0;

  public isLoading: boolean;
  public errorMessage: string;
  public discountValue: string;
  public totalIsLessThanMinimumSpendError: string;
  public pointsToReachMin: number = 0;
  public pagedRedeemablePointsList: number[];
  public showPreviousPageButton: boolean;
  public showNextPageButton: boolean;

  private _redeemSucceeded: boolean;
  private _redeemingRules: LoyaltySchemeRedeemingRules;
  private _earningRules: LoyaltySchemeEarningRules;
  private _redeemablePointsList: number[] = [];
  private _lastRedeemedPoints: number = 0;
  private _currentRedeemListPageIndex: number = 0;
  private _redeemListPageSize: number = this.isMobile ? 2 : 3;
  private _redeemListTotalPages: number = 0;

  constructor(
    private _currencyPipe: CurrencyPipe,
    private _basketService: BasketService
  ) {
    super();
    this.isLoading = true;
    this._redeemingRules = this.tenant?.LoyaltyScheme?.RedeemingRules;
    this._earningRules = this.tenant?.LoyaltyScheme?.EarningRules;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.basket) {
      if (this._redeemSucceeded && this.basket.Issues?.some((x: Issue) => x.IssueType === IssueTypes.OccasionIsNotAvailableAtWantedTime)) {
        this.openWantedTimePicker();
        this._redeemSucceeded = false;
      }

      this.totalIsLessThanMinimumSpend = this.basket.Issues?.some((x: Issue) => x.IssueType === IssueTypes.TotalIsLessThanMinimumSpend);
    }

    if (changes._basketTotal || changes.usersLoyaltyPoints) {
      this._redeemablePointsList = this.getRedeemablePointsList();
      this._redeemListTotalPages = this.getRedeemListTotalPages();
      if (this._redeemListTotalPages > 0 && this._currentRedeemListPageIndex >= this._redeemListTotalPages - 1) {
        this._currentRedeemListPageIndex = this._redeemListTotalPages - 1;
      }
      this.updateCards();
      this.isLoading = false;
    }
  }

  /**
  * takes in a number and multiplies it by the amount a single voucher point is worth to a tenant and returns the result.
  * @param points - the amount of points
  */
  public pointsMonitoryValue(points: number): string {
    const value: number = (points ? points : 0) * (this._redeemingRules ? this._redeemingRules.DiscountValue / this._redeemingRules.PointsValue : 0);
    return this.parseFormatNumber(value);
  }

  /**
  * redeems loyalty on the current basket and closes the loyalty modal
  */
  public async redeemLoyalty(redeemedPoints?: number): Promise<void> {
    this.isLoading = true;

    if (this._userId && this.basket && !this.basket.HasCustomer) {
      await this._basketService.setCustomerOnBasket(this.basket.Id, this._userId);
    }

    if (redeemedPoints) {
      this._lastRedeemedPoints = redeemedPoints;
    } else {
      redeemedPoints = this._lastRedeemedPoints;
    }
    const redeemObservable: Observable<Basket> = this._basketService.redeemLoyaltyOnBasket(this.basket.Id, redeemedPoints);

    if (redeemObservable) {
      redeemObservable
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            error: (x: HttpErrorResponse) => {
              this.showErrorMessage(x.error.title, x.error.detail);
              this.trackException(x, false);
              this.isLoading = false;
            },
            next: () => {
            // if wanted time is invalid it will appear as if loyalty isn't added to basket, so set to true and listen to ngOnChanges
            // to open wanted time picker modal
              this._redeemSucceeded = true;
              this.isLoading = false;
            }
          });
    }
  }

  /**
  * determines whether the you could earn text shows or not
  */
  public showEarnableLoyaltyPoints(): boolean {
    const maxPoints: number = this._earningRules?.MaxPointsPerCustomer;
    return maxPoints ? this.usersLoyaltyPoints < maxPoints : true;
  }

  public backToPreviousPage(): void {
    this._currentRedeemListPageIndex--;
    this.updateCards();
  }

  public goToNextPage(): void {
    this._currentRedeemListPageIndex++;
    this.updateCards();
  }

  /**
   * updates the card with the current redeemable points and whether the redeem button should be disabled or not.
   */
  private updateCards(): void {
    const redeemablePoints: number = this._redeemingRules.MinPointsRequired ?? this._redeemingRules.PointsValue;

    const isEnabled: boolean = this.getMaxRedeemablePoints() >= redeemablePoints && this.usersLoyaltyPoints >= redeemablePoints;
    this.errorMessage = isEnabled
      ? null
      : this.usersLoyaltyPoints < redeemablePoints
        ? 'You do not have enough points to redeem'
        : 'The basket total is too low to redeem this offer';

    this.pointsToReachMin = redeemablePoints - this.usersLoyaltyPoints;
    this.discountValue = `£${this.pointsMonitoryValue(redeemablePoints)}`;
    this.pagedRedeemablePointsList = this.getPagedRedeemablePointsList();
    this.showPagingButtons();
  }

  private getRedeemablePointsList(): number[] {
    const minRedeemablePoints: number = this._redeemingRules.MinPointsRequired ?? this._redeemingRules.PointsValue;
    if (this.usersLoyaltyPoints < minRedeemablePoints) {
      return [];
    }

    let maxRedeemablePoints: number = this.getMaxRedeemablePoints();
    if (this.usersLoyaltyPoints < maxRedeemablePoints) {
      maxRedeemablePoints = this.usersLoyaltyPoints;
    }

    const result: number[] = [];
    for (let i = 1; i * minRedeemablePoints <= maxRedeemablePoints; i++) {
      result.push(i * minRedeemablePoints);
    }

    return result.reverse();
  }

  private getPagedRedeemablePointsList(): number[] {
    const skip: number = this._currentRedeemListPageIndex * this._redeemListPageSize;
    return this._redeemablePointsList.slice(skip, skip + this._redeemListPageSize);
  }

  private getRedeemListTotalPages(): number {
    let totalPages: number = Math.floor(this._redeemablePointsList.length / this._redeemListPageSize);
    if (this._redeemablePointsList.length % this._redeemListPageSize > 0) {
      totalPages += 1;
    }

    return totalPages;
  }

  private showPagingButtons(): void {
    this.showPreviousPageButton = this._currentRedeemListPageIndex > 0;
    this.showNextPageButton = this._currentRedeemListPageIndex < this._redeemListTotalPages - 1;
  }

  /**
   * Opens the wanted time picker modal.
   */
  private openWantedTimePicker(): void {
    const options = {
      autoFocus: false,
      disableClose: true,
      width: this.isMobile ? '100%' : ''
    };

    this.openDialog(this._confirmWantedTimeModal, 'order-wanted-time-modal', options);
  }

  /**
   * returns the max amount of points that can be redeemed on the current basket.
   */
  private getMaxRedeemablePoints(): number {
    const rules: LoyaltySchemeRedeemingRules = this._redeemingRules;
    const discountPerPoint: number = rules.DiscountValue / rules.PointsValue;

    let result: number = this.usersLoyaltyPoints;

    const basketTotalInPoints: number = this._basketTotal / discountPerPoint;
    if (result > basketTotalInPoints) {
      result = basketTotalInPoints;
    }

    if (rules.MinRemainingOrderValue) {
      const redeemableThreshold = this._basketTotal - rules.MinRemainingOrderValue;
      const points: number = redeemableThreshold / discountPerPoint;

      if (result > points) {
        result = points;
      }
    }

    if (rules.MaxDiscountValue) {
      const points: number = (rules.MaxDiscountValue / discountPerPoint);
      if (result > points) {
        result = points;
      }
    }

    if (rules.MaxDiscountPercent) {
      const points: number = (this._basketTotal * (rules.MaxDiscountPercent / 100)) / discountPerPoint;
      if (result > points) {
        result = points;
      }
    }

    return result > 0 ? Math.floor(result) : 0;
  }

  /**
  * returns an integer to two decimal places
  * @memberof BasketChargesComponent
  * @public
  */
  private parseFormatNumber(integer: number): string {
    return integer.toFixed(2) || '0.00';
  }

  /**
   * sets whether the total is less than the minimum spend or not.
   * and sets the `totalIsLessThanMinimumSpendError` error message.
   */
  private set totalIsLessThanMinimumSpend(value: boolean) {
    if (value) {
      const minSpend: string = this.basket.DeliveryMinimumSpend
        ? this._currencyPipe.transform(this.basket.DeliveryMinimumSpend, 'GBP', 'symbol', '1.2-2')
        : 'the minimum required';
      this.totalIsLessThanMinimumSpendError = `To redeem points the basket subtotal must exceed ${minSpend}`;
    } else {
      this.totalIsLessThanMinimumSpendError = null;
    }
  }
}
